import React from 'react';
import './App.css';
import logo from './logo.png';

const App = () => {
  return (
    <div className="container">
      <img src={logo} alt="Hop and Skip Logo" className="logo" />
      <h1>Website Under Construction</h1>
      <p>We're working hard to bring you a new experience. Stay tuned!</p>
    </div>
  );
};

export default App;